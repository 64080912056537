const menu = {
    button: {
        open: document.querySelector(".nav-mobile__button"),
        close: document.querySelector(".nav-mobile__close"),
    },
    class: "nav-mobile__active",
    overlay: document.querySelector(".nav-mobile__overlay"),
    nav: document.querySelector(".nav-mobile"),
    dropDowns: document.querySelectorAll(".nav__item--drop"),
};

export function closeMenu() {
    menu.nav.classList.remove(menu.class);
    menu.overlay.removeAttribute("style");
    document.body.removeAttribute("style");
}

export function openMenu() {
    menu.nav.classList.add(menu.class);
    menu.overlay.style.left = 0;
    document.body.style.overflow = "hidden";
}

export function openSubMenu() {
    const subMenu = this.children[1];
    subMenu.classList.toggle("d-none");
}

// Alterando o estilo do item "Cursos" do menu de navegação

const menuItems = document.querySelectorAll(".nav__item");

const courseItem = Array.from(menuItems).filter(
    (menuItem) => menuItem.innerText == "Cursos"
);

if (courseItem) {
    courseItem[0].style.backgroundColor = "#ff0110";
    courseItem[0].style.color = "#fff";
    courseItem[0].style.padding = "8px 16px";
}

export default menu;
